'use strict';

import {
    Provider,
    ReactReduxContext
} from 'react-redux';

import {
    configureStore
} from '../../stores';

import ErrorBoundary from '../errors/boundary';

import MainLayout from './main';

function ApplicationLayout(props) {
    return (
        <Provider store={configureStore}
                  context={ReactReduxContext}>
            <ErrorBoundary errorType="card">
                <MainLayout isHome={props.isHome}
                            componentId={props.componentId}>
                    {props.children}
                </MainLayout>
            </ErrorBoundary>
        </Provider>
    );
}

ApplicationLayout.propTypes = {
    componentId: PropTypes.string,
    children: PropTypes.object,
    isHome: PropTypes.bool
};

ApplicationLayout.defaultProps = {
    isHome: false
};

export default ApplicationLayout;
